import React, { FunctionComponent, useEffect, useState } from 'react';
import FileDownload from 'js-file-download';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { IApiResult } from '../../interface/IApiResult';
import {
  API_STATUS_PROBLEM,
  DOWNLOAD_FILE_NUM,
  TEST_IMAGE_TYPE,
} from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyAlert } from '../UI/MyAlert';
import { ContentDto } from '../../dto/ContentDto';
import { apiDeleteContent } from '../../service/apiDeleteContent';
import { AddFile } from '../add-file/AddFile';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { Test3DDto } from '../../dto/Test3DDto';
import { DialogTest3DItem } from './DialogTest3DItem';
import { apiGetTest3D } from '../../service/apiGetTest3D';
import { apiAddTest3D } from '../../service/apiAddTest3D';
import { MyTextFiled } from '../UI/MyTextField';

interface Props {
  open: boolean;
  isEdit?: boolean;
  onClose: () => void;
  dto?: ContentDto;
}

export const DialogTest3D: FunctionComponent<Props> = ({
  open,
  dto,
  isEdit,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [test3DDtos, setTest3DDtos] = useState<Test3DDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);
  const [testCnt, setTestCnt] = useState(4);
  let numTest = 0;

  const handleClose = () => {
    setTest3DDtos([]);
    onClose();
  };
  const { section } = useParams();

  const getTest3D = async (route: string, key: string, download?: number) => {
    setBackDrop(true);
    setTest3DDtos([]);
    const apiResult: IApiResult = await apiGetTest3D(route, key, download);
    if (apiResult.isSuccess) {
      if (download === undefined) {
        if (apiResult.data.test !== undefined) {
          setTest3DDtos(apiResult.data.test);
        }
      } else {
        FileDownload(
          apiResult.data,
          `${route}_3D_тест_${dto?.key}_${dto?.title}.txt`
        );
      }
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const addTest3D = async (route: string, key: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiAddTest3D(
      route,
      key,
      testCnt < 2 || testCnt === undefined ? 4 : testCnt
    );
    if (apiResult.isSuccess) {
      getTest3D(section ?? '', dto?.key ?? '');
    }
    setBackDrop(false);
  };

  const deleteContent = async (route: string, type: number, key: string) => {
    const apiResult: IApiResult = await apiDeleteContent(route, type, key);
    if (apiResult.isSuccess) {
      setTest3DDtos([]);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (open) {
      getTest3D(section ?? '', dto?.key ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%', height: '100%' },
        }}
      >
        <BackDropDiv open={backDrop} />
        <MyAlert
          message={alertProps.message}
          severity={alertProps.severity}
          onClose={() => setAlertProps({ ...alertProps, message: '' })}
        />
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '24px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`3D тест - ${dto?.key} ${dto?.title}`}
            </Typography>
            <Stack direction="row" spacing={1}>
              {!isEdit && (
                <Stack direction="row" spacing={1}>
                  <IconButton
                    title="Скачать"
                    size="small"
                    onClick={() =>
                      getTest3D(
                        section ?? '',
                        dto?.key ?? '',
                        DOWNLOAD_FILE_NUM
                      )
                    }
                  >
                    <DownloadIcon fontSize="large" sx={{ color: '#9c27b0' }} />
                  </IconButton>
                </Stack>
              )}
              {isEdit && false && (
                <Stack direction="row" spacing={1}>
                  <AddFile
                    name="fileToUpload"
                    icon={
                      <FileUploadIcon
                        fontSize="large"
                        sx={{ color: '#9c27b0' }}
                      />
                    }
                    typeContent={TEST_IMAGE_TYPE}
                    dto={dto}
                  />
                  <IconButton
                    title="Удалить"
                    size="small"
                    onClick={() =>
                      deleteContent(
                        section ?? '',
                        TEST_IMAGE_TYPE,
                        dto?.key ?? '-11111'
                      )
                    }
                  >
                    <DeleteIcon fontSize="large" sx={{ color: 'red' }} />
                  </IconButton>
                </Stack>
              )}
              <MyTextFiled
                value={testCnt}
                type="number"
                onChange={(e) => setTestCnt(e.target.value)}
                size="small"
                label="Количесство ответов в тесте"
              />
              <IconButton
                title="Добавить"
                size="small"
                onClick={() => addTest3D(section ?? '', dto?.key ?? '-11111')}
              >
                <AddToPhotosIcon fontSize="large" sx={{ color: '#2196f3' }} />
              </IconButton>
              <IconButton title="Закрыть" size="small" onClick={handleClose}>
                <CloseIcon fontSize="large" sx={{ color: '#000000' }} />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {test3DDtos.map((item) => {
              numTest += 1;
              return (
                <div>
                  <DialogTest3DItem
                    isEdit={isEdit === true}
                    num={numTest}
                    key={numTest}
                    keyTitle={dto?.key ?? ''}
                    titleName={dto?.title ?? ''}
                    dto={item}
                    onClick={() => getTest3D(section ?? '', dto?.key ?? '')}
                  />
                  <Divider />
                </div>
              );
            })}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogTest3D.defaultProps = {
  isEdit: false,
  dto: undefined,
};
