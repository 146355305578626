import React, { useEffect, useState } from 'react';
import FileDownload from 'js-file-download';
import { useParams } from 'react-router-dom';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { API_STATUS_PROBLEM, SECTION_NAME } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { ContentDto } from '../../dto/ContentDto';
import { apiGetContents } from '../../service/apiGetContents';
import { RowSectionDivItem } from './RowSectionDivItem';
import { DialogAddTitleDiv } from '../edit/DialogAddTitleDiv';
import { apiGetAtlas3D } from '../../service/apiGetAtlas3D';

export const SectionDiv = () => {
  const { section } = useParams();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [contentDtos, setContentDtos] = useState<ContentDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const getContents = async (route: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetContents(route);
    if (apiResult.isSuccess) {
      setContentDtos(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    getContents(section ?? '');
  };

  const getAtlas3D = async (
    route: string,
    key: string,
    title: string,
    download?: number
  ) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetAtlas3D(route, key, download);
    if (apiResult.isSuccess) {
      FileDownload(
        apiResult.data,
        `${route}_3D_атлас_${key}_${title}_##_abstractMap.txt` ?? ''
      );
    } else if (apiResult.status !== 404) {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const downloadAllFile = async () => {
    for (let i = 0; i < contentDtos.length; i += 1) {
      if (contentDtos[i].has3dAtlas) {
        // eslint-disable-next-line no-await-in-loop
        await getAtlas3D(
          'anatomy',
          contentDtos[i].key,
          contentDtos[i].title,
          1
        );
      }
    }
  };

  useEffect(() => {
    getContents(section ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack width="100%" alignItems="center">
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="90%" padding="10px" spacing={0}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 1000,
              fontSize: '30px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {`${localStorage.getItem(SECTION_NAME)} - Структура`}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              title="Добавить новый раздел"
              size="large"
              onClick={downloadAllFile}
            >
              <AddBoxIcon fontSize="large" sx={{ color: '#c62828' }} />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack marginBottom="40px">
          <div> </div>
        </Stack>
        {contentDtos.map((item) => (
          <div>
            <RowSectionDivItem key={item.id} dto={item} />
            <Divider />
          </div>
        ))}
      </Stack>
      <DialogAddTitleDiv open={openDialog} onClose={handleClose} />
    </Stack>
  );
};
