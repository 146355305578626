import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../constant/constants';
import { IApiParams } from '../interface/IApiParams';
import { getCookie, getLang, secureToken } from '../utils/utils';
import { getApi } from './getApi';

export const apiAddTest3D = async (
  route: string,
  key: string,
  testCnt: number
) => {
  const params: IApiParams = {
    url: `/addTest3D.php?baseName=${route}&key=${key}&testCnt=${testCnt}&title=1&${secureToken()}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': getCookie(ACCESS_TOKEN_NAME),
      'Api-Lang': getLang(),
    },
  };
  return getApi(params, 'POST');
};
