import React, { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

interface Props {
  label: any;
  color?: string;
  disabled?: boolean;
  multiLine?: boolean;
  width?: string;
  size?: any;
  value?: any;
  type?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (e: any) => void;
}

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: 'grey',
  },
});

export const MyTextFiled: FunctionComponent<Props> = ({
  label,
  color,
  multiLine,
  disabled,
  width,
  size,
  value,
  type,
  error,
  helperText,
  onChange,
}) => (
  <CssTextField
    label={label}
    value={value}
    variant="filled"
    type={type}
    onChange={onChange}
    error={(error && value === '') || helperText !== ''}
    multiline={multiLine}
    disabled={disabled}
    style={{
      width,
      background: color,
      fontFamily: 'sans-serif',
    }}
    helperText={error && value === '' ? 'Введите значение' : helperText}
    size={size}
  />
);

MyTextFiled.defaultProps = {
  value: '',
  color: undefined,
  disabled: false,
  multiLine: false,
  width: '100%',
  size: 'large',
  type: 'text',
  error: false,
  helperText: '',
  onChange: undefined,
};
