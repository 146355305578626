import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../constant/constants';
import { IApiParams } from '../interface/IApiParams';
import { getCookie, getLang, secureToken } from '../utils/utils';
import { getApi } from './getApi';
import { getApiFile } from './getApiFile';

export const apiGetTest3D = async (
  route: string,
  key: string,
  download?: number
) => {
  const params: IApiParams = {
    url: `/getTest3D.php?baseName=${route}&print=${download}&${secureToken()}&key=${key}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': getCookie(ACCESS_TOKEN_NAME),
      'Api-Lang': getLang(),
    },
  };
  if (download !== undefined && download === 1) {
    return getApiFile(params, 'GET');
  }
  return getApi(params, 'GET');
};
