import React, { FunctionComponent, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { BASE_URL } from '../../setting/AppSettings';
import { IApiResult } from '../../interface/IApiResult';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { MyTextFiled } from '../UI/MyTextField';
import { Test3DDto } from '../../dto/Test3DDto';
import { apiSaveTest3D } from '../../service/apiSaveTest3D';
import { apiDeleteTest3D } from '../../service/apiDeleteTest3D';

interface Props {
  num: number;
  isEdit: boolean;
  keyTitle: string;
  titleName: string;
  dto?: Test3DDto;
  onClick?: () => void;
}

export const DialogTest3DItem: FunctionComponent<Props> = ({
  num,
  isEdit,
  keyTitle,
  titleName,
  dto,
  onClick,
}) => {
  const { section } = useParams();
  const [testDto, setTestDto] = useState(dto);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const saveText = async (route: string, key: string, title: string) => {
    const apiResult: IApiResult = await apiSaveTest3D(
      route,
      key,
      title,
      testDto
    );
    if (apiResult.isSuccess) {
      setAlertProps({
        message: 'Данные записаны',
        severity: AlertSeverityEnum.info,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const deleteText = async (route: string, key: string, title: string) => {
    const apiResult: IApiResult = await apiDeleteTest3D(
      route,
      key,
      title,
      testDto
    );
    if (apiResult.isSuccess) {
      setAlertProps({
        message: 'Данные удалены',
        severity: AlertSeverityEnum.info,
      });
      if (onClick !== undefined) {
        onClick();
      }
      // setTestDto(dto);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const setArrayOprions = (value: string, index: number) => {
    const array = testDto?.options;
    if (array !== undefined && testDto !== undefined) {
      array[index] = value;
      setTestDto({ ...testDto, options: array });
    }
  };

  const setArrayCorrectOprions = (value: string, index: number) => {
    const array = testDto?.correctOptions;
    if (array !== undefined && testDto !== undefined) {
      array[index] = value;
      setTestDto({ ...testDto, correctOptions: array });
    }
  };

  const setArrayNewCorrectOprions = (value: string, index: number) => {
    const array = testDto?.correctOptions;
    if (array !== undefined && testDto !== undefined) {
      array[index] = value;
      setTestDto({ ...testDto, correctOptions: array });
    }
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack alignItems="center" justifyContent="center" spacing={1}>
        <Stack direction="row" width="100%" alignItems="center" spacing={1}>
          {isEdit && (
            <Stack direction="row" spacing={1}>
              <IconButton
                title="Сохранить"
                size="small"
                onClick={() => saveText(section ?? '', keyTitle, titleName)}
              >
                <SaveIcon fontSize="large" sx={{ color: '#2196f3' }} />
              </IconButton>
            </Stack>
          )}
          <Stack direction="row" spacing={1}>
            <IconButton
              title="Удалить"
              size="small"
              onClick={() => deleteText(section ?? '', keyTitle, titleName)}
            >
              <DeleteIcon fontSize="large" sx={{ color: 'red' }} />
            </IconButton>
          </Stack>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              width: '100%',
              fontSize: '18px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {`Вопрос ${num} - ID в базе ${dto?.id}`}
          </Typography>
        </Stack>
        <Stack spacing={1} width="100%">
          {!isEdit && (
            <img
              alt={testDto?.sceneId}
              src={`${BASE_URL}/${section}/preview3d/${testDto?.targetId}.png`}
              width={400}
              style={{ cursor: 'pointer' }}
            />
          )}
          {isEdit && (
            <Stack spacing={1} width="100%">
              <MyTextFiled
                value={testDto?.sceneId}
                multiLine
                onChange={(e) =>
                  testDto !== undefined &&
                  setTestDto({ ...testDto, sceneId: e.target.value })
                }
                size="small"
                label="Сцена"
              />
              <MyTextFiled
                value={testDto?.targetId}
                multiLine
                onChange={(e) =>
                  testDto !== undefined &&
                  setTestDto({ ...testDto, targetId: e.target.value })
                }
                size="small"
                label="ID"
              />
              <img
                alt={testDto?.sceneId}
                src={`${BASE_URL}/${section}/preview3d/${testDto?.targetId}.png`}
                width={250}
                style={{ cursor: 'pointer' }}
              />
            </Stack>
          )}
        </Stack>
        {testDto?.options.map((item, index) => (
          <Stack spacing={1} width="100%">
            {!isEdit && (
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '14px',
                  color: '#000000',
                  letterSpacing: 1,
                }}
                component="text"
              >
                {item}
              </Typography>
            )}
            {isEdit && (
              <Stack direction="row" spacing={1}>
                <MyTextFiled
                  value={item}
                  multiLine
                  onChange={(e) => setArrayOprions(e.target.value, index)}
                  size="small"
                  label="Ответ"
                />
                <Stack direction="row" spacing={1}>
                  <IconButton
                    title="Выбрать"
                    size="small"
                    onClick={() => setArrayNewCorrectOprions(item, 0)}
                  >
                    <CheckCircleIcon
                      fontSize="large"
                      sx={{ color: '#2196f3' }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
            )}
          </Stack>
        ))}
        {testDto?.correctOptions.map((item, index) => (
          <Stack spacing={1} width="100%">
            {!isEdit && (
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '14px',
                  color: '#4caf50',
                  letterSpacing: 1,
                }}
                component="text"
              >
                {item}
              </Typography>
            )}
            {isEdit && (
              <MyTextFiled
                value={item}
                disabled
                multiLine
                onChange={(e) => setArrayCorrectOprions(e.target.value, index)}
                size="small"
                color="#90caf9"
                label="Правильный ответ"
              />
            )}
          </Stack>
        ))}
        <Divider />
      </Stack>
    </div>
  );
};

DialogTest3DItem.defaultProps = {
  dto: undefined,
  onClick: () => undefined,
};
